import React from 'react';
import { Link } from "gatsby";

import IOTMainImg1 from "../../assets/images/iot-banner-image/iot-main-img1.png";
import IOTShape1 from "../../assets/images/iot-banner-image/iot-shape1.png";

const MainBanner = () => {
    return (
        <div className="iot-main-banner">
            <div className="container">
                <div className="iot-banner-content">
                    <span>Robotic Process Automation</span>
                    <h2>Save Time and Money with Our Robust RPA Services</h2>
                    <p>Our Robust RPA Services are designed to automate repetitive and time-consuming tasks, allowing you to focus on more critical business activities. By implementing our services, you can save time, reduce errors, and cut down on operational costs. Our advanced RPA solutions can streamline your workflows, improve efficiency, and deliver measurable results for your business.</p>
                    
                    <Link to="/contact" className="btn btn-primary">
                        Get Started
                    </Link>
                </div>

                <div className="iot-banner-image">
                    <img 
                        src={IOTMainImg1}
                        className="animate__animated animate__fadeInUp animate__delay-0.8s" 
                        alt="image"
                    />
                
                    <img 
                        src={IOTShape1}
                        className="animate__animated animate__zoomIn" 
                        alt="image"
                    />
                </div>

                <div className="animate-border">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>
    );
}

export default MainBanner;
