import React from "react"
import { Link } from "gatsby"

import CtaShape2 from "../../assets/images/cta-shape2.png"
import Circle from "../../assets/images/circle.png"
import CtaShape from "../../assets/images/cta-shape.png"

const Cta = () => {
  return (
    <div className="iot-cta-area bg-0f054b">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="cta-iot-img">
              <img
                src={CtaShape2}
                className="animate__animated animate__fadeInUp animate__delay-0.6s"
                alt="image"
              />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="cta-iot-content">
              <h3>We infuse emotions into bots with AI</h3>
              <p>
              Scale your business by giving us a chance to integrate artificial intelligence with automation, enabling bots to recognize and respond like humans. This can lead to more personalized and empathetic outcomes, potentially improving the user experience and increasing customer satisfaction. <br />
                <br />
                Some notable processes across domains include,<br />
                <div className="row">
                    <div className="col-6">
                        - Document Understanding
                    </div>
                    <div className="col-6">
                        - Sentiment Analysis
                    </div>
                    <div className="col-6">
                        - OCR Extraction
                    </div>
                    <div className="col-6">
                        - Computer Vision
                    </div>
                    <div className="col-6">
                        - Fraud Analysis & Detection
                    </div>
                    <div className="col-6">
                        - Self healing
                    </div>
                </div>
              </p>

              {/* <Link to="/contact" className="btn btn-primary">
                Get Started!
              </Link> */}
            </div>
          </div>
        </div>
      </div>

      <div className="circle-box">
        <img src={Circle} alt="image" />
      </div>
      <div className="cta-shape">
        <img src={CtaShape} alt="image" />
      </div>
    </div>
  )
}

export default Cta
