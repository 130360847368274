import React from 'react';
import { Link } from "gatsby";

import IOTFeatureImage1 from "../../assets/images/iot-features-image/iot-feature-image1.png";
import IOTFeatureImage2 from "../../assets/images/iot-features-image/iot-feature-image2.png";

const Features = () => {
    return (
        <>
            <div className="iot-features-area ptb-80 bg-f7fafd">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-7 iot-features-content px-5">
                            <h3>We Seamlessly Connect your Systems with RPA</h3>
                            <p>We bridge the gap between your complicated systems with our bots, streamlining and simplifying your business operations to unlock the full potential of automation and free up your team's time for higher-value work.</p>
                            <p>Our RPA integration solution ensures smooth interoperability across all your systems and apps, whether it's a backend or frontend, starting from legacy mainframes, web and desktop apps, services, databases, and more.</p>
                            
                            <Link to="/contact" className="btn btn-primary">
                                Discover now
                            </Link>
                        </div>

                        <div className="col-lg-5 iot-features-image">
                            <img 
                                src={IOTFeatureImage1}
                                className="animate__animated animate__fadeInUp animate__delay-0.6s" 
                                alt="image" 
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="iot-features-area ptb-80">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 iot-features-image">
                            <img 
                                src={IOTFeatureImage2}
                                className="animate__animated animate__fadeInUp animate__delay-0.6s" 
                                alt="image"
                            />
                        </div>
                        
                        <div className="col-lg-6 iot-features-content">
                            <h3>Get More for Less: Our Cost-Effective Services Deliver Maximum Value</h3>
                            <p>Experience maximum value for your investment with our cost-effective services. Our solutions provide superior quality and results while saving you money, enabling you to achieve your business goals within your budget.</p>
                            <p>Choose our cost-effective services to achieve your business goals without compromising on quality or value. Our solutions are designed to help you succeed without breaking the bank, enabling you to invest in your business's growth and long-term success.</p>

                            <Link to="/contact" className="btn btn-primary">
                                Get Quote
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Features;
