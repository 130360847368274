import React from "react"
import { Link } from "gatsby"
import * as Icon from "react-feather"

import Icon1 from "../../assets/images/icon1.png"
import Icon2 from "../../assets/images/icon2.png"
import Icon3 from "../../assets/images/icon3.png"

const WhyWeAreBest = () => {
  return (
    <div className="iot-why-choose-us pt-80">
      <div className="container">
        <div className="section-title">
          <h2>How we do ?</h2>
          <div className="bar"></div>
          <p>
            Our proven methodology combines cutting-edge technology, industry
            best practices, and years of experience to deliver custom automation
            solutions that make your business more efficient, effective, and
            agile.
          </p>
        </div>

        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="single-iot-box">
              <div className="icon">
                <img src={Icon1} alt="image" />
              </div>
              <h3>Analyse, Discover & Plan</h3>
              <p>
                Our comprehensive analysis & understanding phase, ensures only
                potential candidates are picked to meet your business goals.
              </p>
          {/*    <Link to="/service-details">
                <Icon.ArrowRight />
  </Link>*/}
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-iot-box">
              <div className="icon">
                <img src={Icon2} alt="image" />
              </div>
              <h3>Build, Test & Deploy</h3>
              <p>
                Our automation solutions undergo rigorous development, testing
                and seamless deployment to drive your business success.
              </p>
        {/*      <Link to="/service-details">
                <Icon.ArrowRight />
</Link>*/}
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="single-iot-box">
              <div className="icon">
                <img src={Icon3} alt="image" />
              </div>
              <h3>Manage, Monitor & Reiterate</h3>
              <p>
                Our continuous management & monitoring process ensures the
                automation is consistently optimized for maximum
                efficiency & ROI.
              </p>
         {/*     <Link to="/service-details">
                <Icon.ArrowRight />
</Link>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyWeAreBest
