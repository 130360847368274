import React from 'react';
import { Link } from "gatsby";
import * as Icon from 'react-feather';

const OurServices = () => {
    return (
        <div className="iot-services-area pt-80 pb-50">
            <div className="container justify-content-center">
                <div className="section-title">
                    <h2>Our Extensive Domain Capabilities</h2>
                    <div className="bar"></div>
                    <p>We have in-depth knowledge of the challenges & requirements of each industry, enabling us to build robots across a wide range of industries.</p>
                </div>
                
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-repair-services bg1">
                            <div className="icon">
                                <i className="flaticon-skyline"></i>
                            </div>

                            <h3>Banking & Finance</h3>
                            <p>We automate crucial tasks such as invoice processing, account reconciliation, AML KYC, tax filing and financial reporting.</p>
                            
                            {/*<Link to="#">
                                <Icon.ArrowRight />
    </Link>*/}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-repair-services bg2">
                            <div className="icon">
                                <i className="flaticon-car-insurance"></i>
                            </div>

                            <h3>Insurance & Healthcare</h3>
                            <p>We train bots to assist you in claims handling, data management, appointment scheduling, allowing you to focus on patient care.</p>
                         {/*   <Link to="#">
                                <Icon.ArrowRight />
</Link>*/}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-repair-services bg3">
                            <div className="icon">
                                <i className="flaticon-chip"></i>
                            </div>

                            <h3>Customer Service</h3>
                            <p>Our assistants help manage customer queries & support requests, automate data entry & record keeping to improve response time.</p>
                            
                       {/*     <Link to="#">
                                <Icon.ArrowRight />
</Link>*/}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-repair-services bg4">
                            <div className="icon">
                                <i className="flaticon-software"></i>
                            </div>

                            <h3>Media & Telecommunication</h3>
                            <p>We help you improve customer experience by automating management activities for content, network , billing, orders and marketing.</p>
                            
                         {/*  <Link to="#">
                                <Icon.ArrowRight />
</Link>*/}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-repair-services bg5">
                            <div className="icon">
                                <i className="flaticon-factory"></i>
                            </div>

                            <h3>Manufacturing & Supply Chain</h3>
                            <p>We build workflows to manage your production, QC, inventory, procurement, logistics to improve efficiency & reduce errors.</p>
                            
                          {/*  <Link to="#">
                                <Icon.ArrowRight />
</Link>*/}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-repair-services bg6">
                            <div className="icon">
                                <i className="flaticon-gear"></i>
                            </div>

                            <h3>Human Resources</h3>
                            <p>We reduce repetitive HR tasks like onboarding, payroll processing, benefits administration, freeing up time to focus on strategic initiatives.</p>
                            
                           {/* <Link to="#">
                                <Icon.ArrowRight />
</Link>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurServices;
